// using these custom functions as oppose to js classList methods
// for special handling of svg's

const styleButton = (button, classes) => {
  // classes is an array of strings, ie ['active', 'loading']
  button.classList.add(...classes);
};

const resetButton = (button, classes) => {
  const buttonTypes = ['current-item', 'refresh'];

  // FontAwesome uses the browsers MutationObserver interface to watch changes to the page
  // https://fontawesome.com/how-to-use/with-the-api/setup/configuration#observemutations
  // therefore caching the element does not work and we must query for it after the page updates
  button = buttonTypes.includes(button.dataset.button)
    ? button.querySelector('svg')
    : button;
  // classes is an array of strings, ie ['active', 'loading']
  button.classList.remove(...classes);
};

// use 'saveSubmit' method in a submit handler
const detectSubmit = {
  saveSubmit(form) {
    form.dataset.submitted = true;
  },
  submitted(form) {
    return form.dataset.submitted;
  },
};

const generateAssociatedFields = (button) => {
  const time = new Date().getTime();
  const regexp = new RegExp(button.dataset.id, 'g');
  return { id: time, newFields: button.dataset.fields.replace(regexp, time) };
};

const removeAttachment = (button) => {
  const associatedAttachment = button.closest('.associated-fields');
  associatedAttachment.style.display = 'none';
  associatedAttachment.querySelector('input[type=hidden').value = true;
};

// b is a boolean. true enables, false disables.
const enableElement = (id, b) => {
  const elem = document.getElementById(id);
  if (b) {
    elem.removeAttribute('disabled');
  } else {
    elem.setAttribute('disabled', 'disabled');
  }
};

// b is a boolean. true shows, false hides.
// Adapted from https://gomakethings.com/how-to-show-and-hide-elements-with-vanilla-javascript/
const showElement = function (id, b) {
  const elem = document.getElementById(id);
  const style = b ? 'block' : 'none';
  elem.style.display = style;
};

/*
 * Concatenate two HtmlCollection objects.
 * Returns a new array that has all elements from both collections.
 * https://stackoverflow.com/questions/24133231/concatenating-html-object-arrays-with-javascript
 *
 * NOTE:
 * You may not need this function if you can fetch both
 * collections at once through
 * document.querySelector()/querySelectorAll().
 */
const concatCollections = function (htmlColl1, htmlColl2) {
  let allItems = [];
  allItems = Array.prototype.concat.apply(allItems, htmlColl1);
  allItems = Array.prototype.concat.apply(allItems, htmlColl2);
  return allItems;
};

// Remove all items from a list.
const removeAllListItems = function (id) {
  const list = document.getElementById(id);
  while (list.firstChild) {
    list.removeChild(list.firstChild);
  }
};

// Capitalize the first character in the string s.
// Returns a new string; s is unchanged.
const capitalizeFirst = function (s) {
  if (s.length === 0) {
    return s;
  }
  return s[0].toUpperCase() + s.slice(1);
};

/*
 * Use this to get around the occasional issue where refreshing a page
 * would repost, with a browser warning, etc.
 *
 * On Firefox, this appears to turn the post into a get, which is perfect.
 * (Or maybe it literally replaces the post with a get?)
 */
const clearPostData = () => {
  history.replaceState(null, document.title, location.href);
};

/*
 * Use this function with the `await` keyword, in an `async` function.
 * For an example, see `ensureIframeLoaded` in app/javascript/utils/iframe_checker.js
 */
function sleep(seconds) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

const isMobileScreen = (width = 576) => window.innerWidth <= width;

export const evalTrueFalse = (bool) => {
  if (bool == 'true') {
    return true;
  }
  if (bool == 'false') {
    return false;
  }
};

export const postData = (data, route) => {
  return $.post(route, data, null, 'json');
};

/**
 * use to toggle a class in TR element base on the state (checked/unchecked) of a check box in TD
 * @param {TR element} rowContainCheckBox
 * @param {string} className this is the class of the checkbox
 * @param {string} toogleClassName a CSS class that needed to be toggle
 * @param {bool} bool desired state of the checkbox
 */
export const handleCheckbox = (
  rowContainCheckBox,
  className,
  toogleClassName,
  bool
) => {
  //console.log(bool, rowContainCheckBox, className, toogleClassName);
  var box = rowContainCheckBox.querySelector(`.${className}`);
  //console.log(box);
  if (box) {
    rowContainCheckBox.querySelector(`.${className}`).checked = bool;
    //console.log(rowContainCheckBox.querySelector(`.${className}`));
  }
  if (bool) {
    rowContainCheckBox.classList.add(`${toogleClassName}`);
    return;
  }
  rowContainCheckBox.classList.remove(`${toogleClassName}`);
  //console.log(rowContainCheckBox);
};

function printNoCommentsInit() {
  let btn = document.getElementById('printNoCommentBtn');
  if (btn) {
    btn.addEventListener('click', () => {
      let comments = document.querySelectorAll('.comments');
      if (comments) {
        comments.forEach((el) => {
          el.classList.add('d-print-none');
        });
      }
      window.print();
    });
  }
}

function printWithCommentsInit() {
  let btn = document.getElementById('printWithCommentsBtn');
  if (btn) {
    btn.addEventListener('click', () => {
      let comments = document.querySelectorAll('.comments');
      if (comments) {
        comments.forEach((el) => {
          el.classList.remove('d-print-none');
        });
      }
      window.print();
    });
  }
}
export {
  printNoCommentsInit,
  printWithCommentsInit,
  capitalizeFirst,
  clearPostData,
  concatCollections,
  detectSubmit,
  enableElement,
  generateAssociatedFields,
  removeAllListItems,
  removeAttachment,
  resetButton,
  showElement,
  sleep,
  styleButton,
  isMobileScreen,
};
