/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '@fortawesome/fontawesome-pro/js/all.js';
import ujs from '@rails/ujs';
import { printNoCommentsInit, printWithCommentsInit } from '../utils/util';

ujs.start();

console.log('Hello World from Webpacker');

// MAIN SIDE NAVIGATION PANEL
const toggleMainSideNav = (e) => {
  e.preventDefault();
  if (document.body.classList.toggle('push')) {
    // hide other menu panels when main menu is out
    document.body.classList.remove('cal-item-push', 'enrolling-push');
  }
};

const iLog = (msg) => {
  const log = document.getElementById('ipad-log');
  if (log) {
    log.appendChild(document.createElement('br'));
    log.appendChild(document.createTextNode(msg));
  }
};

document.addEventListener('click', (e) => {
  let target = e.target;

  if (target.closest('.main-side-nav-trigger')) {
    toggleMainSideNav(e);
  }
});

document
  .getElementById('app-bill-search-form')
  .addEventListener('submit', (e) => {
    e.preventDefault();
    const form = e.target;
    const bill = form.search.value;
    window.location.assign(
      `${window.location.protocol}//${window.location.host}/bills/${bill}`
    );
  });

printNoCommentsInit();
printWithCommentsInit();

// this script set the is mobile device input for mobile detection

var is_mobile;
var savedMode = sessionStorage.getItem('is-mobile-device');
// sessionStorage.removeItem('is-mobile-device');
const mobileCheckbox = document.getElementById('mobile-checkbox');
if (
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
) {
  is_mobile = true;
} else {
  is_mobile = false;
}
if (savedMode !== null) {
  if (savedMode === 'true') {
    is_mobile = true;
  }
  if (savedMode === 'false') {
    is_mobile = false;
  }
}
if (savedMode === 'true') {
  mobileCheckbox.checked = true;
}
document.getElementById('is-mobile-device').value = is_mobile;

// for testing
let isMobileDisplay = document.getElementById('is-mobile');
if (is_mobile && isMobileDisplay) {
  isMobileDisplay.innerHTML = 'Mobile';
} else if (isMobileDisplay) {
  isMobileDisplay.innerHTML = 'Desktop';
}

if (mobileCheckbox) {
  mobileCheckbox.addEventListener('change', (e) => {
    sessionStorage.setItem('is-mobile-device', e.target.checked);
    setTimeout(() => {
      location.reload();
    }, 100);
  });
}

let iframes = document.querySelectorAll('iframe');

// var iframe = document.getElementById('iframeChecker');
// if (!iframe) {
//   iframe = document.getElementById('iframeName');
// }

var refreshButton = document.getElementById('refresh-iframe');
var url;
if (refreshButton) {
  refreshButton.addEventListener('click', function () {
    iLog('refreshing iframe');
    iframes.forEach((iframe) => {
      iframe.src = iframe.src;
      iLog(iframe.src);
    });
  });
}
const replaceIframeUrl = () => {
  iframes.forEach((iframe) => {
    if (iframe) {
      url = iframe.src;
    } else {
      return;
    }
    var is_mobile = document.getElementById('is-mobile-device').value;
    if (is_mobile === 'true') {
    }
    // if we are on a desktop, we want to bypass google drive viewer and go directly to the pdf
    else if (is_mobile === 'false') {
      // strip the google drive viewer from the url
      var url1 = url.split(
        'https://drive.google.com/viewerng/viewer?embedded=true&url='
      )[1];
      var url2 = url.split('https://docs.google.com/viewer?url=')[1];
      // whichever one is not undefined is the one we want
      if (url1 === undefined) {
        url = url2;
      } else {
        url = url1;
      }
      // set the iframe src to the pdf
      if (url === undefined) {
        return;
      }
      iframe.src = url;
      // console.log(url);
    }
  });
};
replaceIframeUrl();
